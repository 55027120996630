import * as React from "react"
import { Image, useColorModeValue } from "@chakra-ui/react"
import lightLogo from "../../images/light-logo-square.png"
import darkLogo from "../../images/dark-logo-square.png"

export const FooterLogo = () => {
	const logo = useColorModeValue(lightLogo, darkLogo)
	return (
		<Image
			borderRadius="lg"
			width={{ md: 40 }}
			src={logo}
			alt="Exurgo Logo Square"
			display="inline-block"
			margin="0 0 0 0"
			htmlWidth="200px"
			htmlHeight="200px"
			objectFit="cover"

		/>
	);
}

