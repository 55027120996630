import React from 'react';
import { Box, useColorModeValue } from "@chakra-ui/react";

import { ColorModeSwitcher } from '../color-mode-switcher'
import { HeaderLogo } from '../header/header-logo'

export const Header = () => {
  const bg = useColorModeValue("white", "#003057")
  

  return (
      <Box width="100%" padding="none" alignItems="baseline" py={4} mr={12} background={bg}>
        <Box padding="0 0 0 3%" align="left" width="50%" display="inline-block"><HeaderLogo /></Box>
        <Box padding="0 0 0 3%" align="right" width="40%" display="inline-block" >
        </Box>
        <Box width="10%" display="inline-block"><ColorModeSwitcher justifySelf="flex-end" /></Box>
      </Box>
  );
}