import React from 'react';
import { Box, Icon, Text, Link, Center, Flex, Spacer, Wrap, WrapItem, useColorModeValue } from "@chakra-ui/react";
import { FooterLogo } from './footer-logo';
import { AiFillTwitterCircle } from "react-icons/ai";

export const SubFooter = () => {

  return (
    <Box  display="block" p={{sm: "1", md:"30px 1px 0px 1px"}}  bg="#b0a170" color ="#003057" width="100%" fontSize="sm"  >
      © 2021 Exurgo, LLC. All Rights Reserved
    </Box>
  );
}