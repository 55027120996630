import React, { useState } from 'react';
import {
    Flex,
    Box,
    Heading,
    useColorModeValue
} from "@chakra-ui/react";


export const ComingSoonPage = () => {

    return (

            <Flex  width="full" align="center" justifyContent="center" pt={24} pb={24}>
                <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
                    <Box textAlign="center">
                        <Heading p={2}> Let's make something special.</Heading>
                    </Box>
                    <Box my={4} textAlign="left">
                    </Box>
                </Box>
            </Flex>
    );
}