import React from 'react';
import { Box, Icon, Text, Link, Center, Flex, Spacer, Wrap, WrapItem, useColorModeValue } from "@chakra-ui/react";
import { FooterLogo } from './footer-logo';
import { TiSocialTwitterCircular, TiSocialLinkedinCircular } from "react-icons/ti";
import { FaEnvelopeOpenText } from 'react-icons/fa'
import { SubFooter } from './sub-footer';

export const Footer = () => {
	const bg = useColorModeValue("#f2f2f2", "#003057")
  const color = useColorModeValue("gray.500", "white")
  return (
    <Box bg={bg} pt={4} pb={1} pl={4} pr={4} display={{ md: "flex" }} justifyContent={{lg:"center"}}>
      <Box display={{ md: "flex" }}>
        <Box flexShrink={0}>
          <FooterLogo />
          <Link
            mt={1}
            display="block"
            fontSize="lg"
            lineHeight="normal"
            fontWeight="thin"
            href="mailto:connect@exurgo.io"
          >
            <Icon color="#b0a170" as={FaEnvelopeOpenText} /> connect@exurgo.io
          </Link>
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize="xx-large"
            letterSpacing="wide"
            color="#b0a170"
          >
            <Link
              mt={1}
              lineHeight="normal"
              fontWeight="semibold"
              href="https://www.twitter.com/_exurgo_"
            >
              <Icon as={TiSocialTwitterCircular} />
            </Link>
            <Link
              mt={1}
              lineHeight="normal"
              fontWeight="semibold"
              href="#"
            >
              <Icon as={TiSocialLinkedinCircular} />
            </Link>
            </Text>
        </Box>
      </Box>
      <Box display={{ md: "flex" }} pl={{lg:"50%", md:"40%"}} textAlign={{sm:"center", md:"right"}}>
        
        <Box flexShrink={0} >
          <Box m={{ lg: 3, sm: 2 }} color={color} fontWeight="bold" fontSize="lg">Services</Box>
          <Box m={{ lg: 3, sm: 2 }} color={color} fontSize="md">
            Cloud Consulting<br />
            Software Development<br />
            DevOps Engineering<br />
          </Box>
        </Box>
        <Box flexShrink={0} >
          <Box m={{ lg: 3, sm: 2 }} color={color} fontWeight="bold" fontSize="lg">Research</Box>
          <Box m={{ lg: 3, sm: 2 }} color={color} fontSize="md">
            Machine Learning<br />
            Natural Language<br />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

