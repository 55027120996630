import React, { useState } from 'react';
import {
    Flex,
    Box,
    Heading,
    useColorModeValue,
    Grid
} from "@chakra-ui/react";
import { Header } from '../components/header/header';
import { Router, RouteComponentProps } from "@reach/router";
import { ComingSoonPage } from './coming-soon-page';
import { Footer } from '../components/footer/footer';
import { SubFooter } from '../components/footer/sub-footer';


export const EntryPage = () => {
    const bg = useColorModeValue("white", "#003057")

    return (
        <Box bg={bg} margin="0" padding ="0" textAlign="center" fontSize="xl">
        <Grid minH="100vh"  >
            <Header />
            <ComingSoonPage/>
            <Footer/>
            <SubFooter/>
        </Grid>
    </Box>
    );
}