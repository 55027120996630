import * as React from "react"
import { Image, useColorModeValue } from "@chakra-ui/react"
import lightLogo from "../../images/light-logo.png"
import darkLogo from "../../images/dark-logo.png"

export const HeaderLogo = () => {
	const logo = useColorModeValue(lightLogo, darkLogo)
	return (
		<Image
			display="inline-block"
			margin="0 0 0 0"
			htmlWidth="150px"
			htmlHeight="50px"
			objectFit="cover"
			src={logo}
			alt="Exurgo Logo"
		/>
	);
}

