import * as React from "react"
import {
	ChakraProvider,
	Box,
	Grid,
	theme,
	Spinner,
	useColorModeValue,
} from "@chakra-ui/react";
import { Router, RouteComponentProps } from "@reach/router";
import { Header } from "./components/header/header";
import { ComingSoonPage } from "./pages/coming-soon-page";
import { EntryPage } from "./pages/entry-page";


const RouterPage = (
	props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;


export const App = (children: any) => (

	<ChakraProvider theme={theme}>
			<Router>
                <RouterPage path="/" pageComponent={<EntryPage />} />
            </Router>
	</ChakraProvider>
)

